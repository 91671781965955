<template>
  <div class="col-12 p-0 fixed-bottom">
    <div class="row m-0 background-green py-3 justify-content-center align-items-center">
      <div class="col-6">
        <div class="row justify-content-end">
          <div class="col-md-auto">
            <slot name="back" />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-md-auto">
            <slot name="next" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SadaqahFooter'
}
</script>
